var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-treeview',_vm._b({attrs:{"open-all":"","activable":"","selectable":!_vm.$attrs['disabled'],"open-on-click":"","selected-color":"primary","items":_vm.sections,"item-children":"allowedPermissions","item-key":"key","parent-is-disabled":true,"selection-type":"independent"},scopedSlots:_vm._u([(_vm.$attrs['disabled'])?{key:"prepend",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [(selected || (item.allowedPermissions && item.allowedPermissions.every(function (ref) {
	var key = ref.key;

	return _vm.permissions.includes(key);
})))?_c('v-icon',{attrs:{"dark":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckBoxOutline)+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCheckboxBlankOutline)+" ")])]}}:null],null,true),model:{value:(_vm.permissions),callback:function ($$v) {_vm.permissions=$$v},expression:"permissions"}},'v-treeview',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }
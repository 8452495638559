<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead class="position-relative">
          <tr>
            <th
              class="text-uppercase"
              @click="changeOrder('EMAIL')"
            >
              Email
              <sorting-arrows :direction="column === 'EMAIL' ? order : null" />
            </th>
            <th
              v-if="role == 'superadmin'"
              class="text-center text-uppercase"
              @click="changeOrder('ROLE')"
            >
              Role
              <sorting-arrows :direction="column === 'ROLE' ? order : null" />
            </th>
            <th
              class="text-center text-uppercase"
              @click="changeOrder('LAST_SESSION')"
            >
              Last Session
              <sorting-arrows :direction="column === 'LAST_SESSION' ? order : null" />
            </th>
            <th class="text-center text-uppercase">
              Actions
            </th>
          </tr>

          <v-progress-linear
            bottom
            absolute
            indeterminate
            :active="$apollo.queries.users.loading"
          ></v-progress-linear>
        </thead>
        <tbody class="position-relative">
          <tr v-if="admins.length == 0">
            <td
              colspan="4"
              class="text-center my-5"
            >
              <h3>
                No items
              </h3>
            </td>
          </tr>
          <tr
            v-for="item in admins"
            v-else
            :key="item.id"
          >
            <td>{{ item.email }}</td>
            <td
              v-if="role == 'superadmin'"
              class="text-center"
            >
              {{ item.role[0].toUpperCase() + item.role.slice(1) }}
            </td>
            <td class="text-center">
              {{ item.last_session ? $moment(item.last_session).format('YYYY-MM-DD') : 'Never' }}
            </td>
            <td class="text-center">
              <div v-if="!$userService.isCurrentUser(item)">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="$can('update', 'Administrators')"
                      v-bind="attrs"
                      icon
                      small
                      @click="handleEdit(item)"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="$can('destroy', 'Administrators')"
                      v-bind="attrs"
                      icon
                      small
                      @click="handleDelete(item)"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiTrashCanOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      small
                      @click="handleView(item)"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiEyeOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <pagination
      v-if="admins"
      :count="countPages"
      :page.sync="currentPage"
    />
    <admin-dialog-form
      v-if="showModal && $can('update', 'Administrators')"
      :show.sync="showModal"
      :admin.sync="selectedAdmin"
      :role="role"
      @success="getAdmins()"
    />
    <delete-dialog
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :admin.sync="selectedAdmin"
      title="Delete User"
      @success="getAdmins()"
    />
  </div>
</template>

<script>
import { getCurrentInstance, ref } from '@vue/composition-api'
import { mdiPencilOutline, mdiTrashCanOutline, mdiEyeOutline } from '@mdi/js'
import Pagination from '@/views/components/pagination/Pagination.vue'
import gql from 'graphql-tag'
import AdminDialogForm from './AdminDialogForm.vue'
import DeleteDialog from '../DeleteDialog.vue'
import SortingArrows from '../SortingArrows.vue'

export default {
  name: 'AdminsTable',
  components: {
    Pagination,
    AdminDialogForm,
    DeleteDialog,
    SortingArrows,
  },
  props: {
    search: {
      required: false,
      default: null,
      type: String,
    },
  },
  apollo: {
    users: {
      query: gql`
        query ($page: Int!, $first: Int, $search: String, $orderBy: [QueryUsersOrderByOrderByClause!]) {
          users(page: $page, first: $first, search: $search, orderBy: $orderBy) {
            data {
              id
              email
              last_session
              role
              abilities
            }
            paginatorInfo {
              currentPage
              lastPage
            }
          }
        }
      `,
      debounce: 150,
      variables() {
        return {
          search: this.search ?? undefined,
          first: 10,
          orderBy: [
            {
              column: this.column ?? 'EMAIL',
              order: this.order ?? 'ASC',
            },
          ],
          page: this.currentPage,
        }
      },
      result(result) {
        this.admins = result.data.users.data
        this.countPages = result.data.users.paginatorInfo.lastPage
        this.currentPage = result.data.users.paginatorInfo.currentPage
      },
      errorPolicy: 'all',
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy

    const role = localStorage.getItem('userRole')
    const admins = ref([])
    const currentPage = ref(1)
    const countPages = ref(1)
    const searchProp = ref(props.search)
    const showModal = ref(false)
    const showDeleteModal = ref(false)
    const selectedAdmin = ref(null)
    const column = ref('EMAIL')
    const order = ref('ASC')

    const handleView = item => {
      selectedAdmin.value = item
      showModal.value = true
    }
    const handleEdit = item => {
      if (vm.$userService.isCurrentUser(item)) {
        vm.$toastr.e('Unable to edit yourself!')

        return
      }

      selectedAdmin.value = item
      showModal.value = true
    }
    const handleDelete = item => {
      if (vm.$userService.isCurrentUser(item)) {
        vm.$toastr.e('Unable to remove yourself!')

        return
      }

      if (admins.value.length <= 1) {
        vm.$toastr.e('Unable to remove last user!')

        return
      }

      selectedAdmin.value = item
      showDeleteModal.value = true
    }

    return {
      icons: {
        mdiEyeOutline,
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
      admins,
      role,
      currentPage,
      countPages,
      searchProp,
      showModal,
      selectedAdmin,
      showDeleteModal,
      column,
      order,

      handleView,
      handleEdit,
      handleDelete,
    }
  },

  methods: {
    getAdmins() {
      this.$apollo.queries.users.refetch()
    },
    changeOrder(column) {
      this.column = column
      this.order = this.order === 'ASC' ? 'DESC' : 'ASC'
    },
  },
}
</script>

<template>
  <v-treeview
    v-model="permissions"
    v-bind="$attrs"
    open-all
    activable
    :selectable="!$attrs['disabled']"
    open-on-click
    selected-color="primary"
    :items="sections"
    item-children="allowedPermissions"
    item-key="key"
    :parent-is-disabled="true"
    selection-type="independent"
  >
    <template
      v-if="$attrs['disabled']"
      v-slot:prepend="{ item, selected }"
    >
      <v-icon
        v-if="selected || (item.allowedPermissions && item.allowedPermissions.every(({ key }) => permissions.includes(key)))"
        dark
        color="primary"
      >
        {{ icons.mdiCheckBoxOutline }}
      </v-icon>
      <v-icon v-else>
        {{ icons.mdiCheckboxBlankOutline }}
      </v-icon>
    </template>
  </v-treeview>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
  computed,
  watch,
  nextTick,
} from '@vue/composition-api'
// import permissions from '@/views/components/select/permissions'
import { mdiCheckBoxOutline, mdiCheckboxBlankOutline } from '@mdi/js'

export default {
  name: 'SelectPermissions',
  props: {
    value: {
      type: Array,
      require: false,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    // const val = ref([])
    // const isMounted = false
    // const allSelected = ref(false)
    // const unselectAll = ref(false)
    // const handleSelect = ref(false)

    const permissions = ref([])
    const sections = inject('appSections', [])

    const sectionCount = computed(() =>
      sections.value.reduce((acc, curr) => acc + ((curr?.allowedPermissions?.length || 0)), 0))

    watch([permissions], () => {
      if (permissions.value.includes('*')) {
        emit('input', ['*'])
      } else {
        emit('input', permissions.value.length === sectionCount.value ? ['*'] : permissions.value)
      }
    })

    onMounted(() => {
      nextTick(() => {
        permissions.value = props.value.includes('*')
          ? sections.value.flatMap(s => s.allowedPermissions.map(({ key }) => key))
          : props.value
      })
    })

    return {
      // val,
      // isMounted,

      sections,
      permissions,

      icons: {
        mdiCheckBoxOutline,
        mdiCheckboxBlankOutline,
      },

      //   allSelected,
      //   unselectAll,
      //   handleSelect,
    }
  },
  // computed: {
  //   usersPermissions: {
  //     get() {
  //       let allPermissions = []
  //       this.permissions.forEach(permission => {
  //         permission.children.forEach(p => allPermissions.push(p))
  //       })

  //       // First time only
  //       if (this.isMounted) {
  //         this.isMounted = false
  //         if (!this.value) return []

  //         let users_permissions = []
  //         this.value.forEach(permission => {
  //           if (permission != '*') {
  //             //   this.allSelected = false
  //             this.$emit('all', false)

  //             const parent = permission.split(':')[0]
  //             const name = permission.split(':')[1]
  //             const parentPermission = this.permissions.find(permission => permission.name === parent)
  //             const childPermission = parentPermission.children.find(permission => permission.name === name)

  //             users_permissions.push(childPermission)
  //           } else {
  //             //   this.allSelected = true
  //             this.$emit('all', true)

  //             users_permissions = allPermissions
  //           }
  //         })

  //         return users_permissions
  //       }

  //       // if (this.handleSelect == true) {
  //       //   this.handleSelect = false
  //       //   if (this.allSelected) return allPermissions
  //       //   if (this.unselectAll) {
  //       //     this.unselectAll = false

  //       //     return []
  //       //   }
  //       // }

  //       this.$emit('all', allPermissions.length == this.value.length ? true : false)

  //       // this.checkAllSelected(allPermissions, this.value)

  //       return this.value
  //     },
  //     set(value) {
  //       // if (this.permissions == value) this.allSelected = true
  //       // else this.allSelected = false
  //       this.$emit('input', value)
  //     },
  //   },
  // },
  // mounted() {
  //   this.isMounted = true
  // },
  //   watch: {
  //     allSelected(newVal, oldVal) {
  //       if (!newVal && newVal !== oldVal) {
  //         this.unselectAll = true
  //         this.$emit('all', false)
  //       }
  //     },
  //   },
  //   methods: {
  //     checkAllSelected(allPermissions, usersPermissions) {
  //       if (allPermissions.length === this.value.length) this.allSelected = true
  //       else this.allSelected = false
  //     },
  //   },
}
</script>

<style>
button + button.v-treeview-node__checkbox {
  display: none !important;
}
</style>

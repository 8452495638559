<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="adminsEmail"
                dense
                label="Email"
                :disabled="admin != null"
                type="email"
                name="email"
                :error-messages="errorMessages.email"
                hide-details="auto"
              ></v-text-field>
              <div class="validationError pt-1" v-if="!$v.adminsEmail.required  || !$v.adminsEmail.email">Please enter valid email.</div>
            </v-col>
            <!-- <v-col cols="12" v-if="usersRole === 'superadmin'">
            <v-autocomplete
              :items="['Admin', 'Superadmin']"
              label="Role"
              dense
              v-model="adminsRole"
            ></v-autocomplete>
            </v-col> -->
            <v-col
              cols="12"
              class="v-label"
            >
              Permissions
            </v-col>
            <v-col cols="12">
              <select-permissions
                v-model="userPermissions"
                :disabled="$userService.isCurrentUser(admin)"
                @all="allSelected = $event"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="closeModal"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!$userService.isCurrentUser(admin)"
          color="success"
          :disabled="!isValid || $v.$invalid"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import { computed, onMounted, ref, getCurrentInstance, watch, inject } from '@vue/composition-api'
// import { required, emailValidator } from '@core/utils/validation'
import { required, email } from 'vuelidate/lib/validators'
import gql from 'graphql-tag'
// import UserMixin from '@/mixins/UserMixin'
import SelectPermissions from '../select/SelectPermissions.vue'

export default {
  name: 'AdminDialogForm',
  // mixins: [UserMixin],
  components: { SelectPermissions },
  props: {
    admin: {
      type: Object,
      required: false,
      default: null,
    },
    role: {
      type: String,
      required: true,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const isDialogVisible = ref(props.show)
    const adminsEmail = ref(undefined)
    // const adminsRole = ref(undefined)
    const errorMessages = ref([])
    // const usersRole = ref(props.role)
    const vm = getCurrentInstance().proxy
    const userPermissions = ref([])
    const allSelected = ref(false)

    const title = computed(() => {
      if (props.admin === null) return 'Add new administrator'

      if (vm.$userService.isCurrentUser(props.admin)) return 'Show administrator'

      if (props.admin) return 'Edit administrator'
    })

    // const sections = inject('appSections', [])

    const closeModal = () => {
      isDialogVisible.value = false
      emit('update:show', false)
      emit('update:admin', null)
    }

    onMounted(() => {
      if (props.admin !== null) {
        // adminsRole.value = props.admin.role[0].toUpperCase() + props.admin.role.slice(1)
        adminsEmail.value = props.admin.email
        userPermissions.value = Array.isArray(props.admin.abilities) ? props.admin.abilities : []
      }
    })

    // if (props.role === 'admin') {
    //   adminsRole.value = 'admin'
    // }

    // const getUsersAbilities = computed(() => {
    //   //   if (adminsRole === 'Superadmin') return ['*']
    //   if (allSelected.value) return ['*']
    //   let newPermissions = []
    //   userPermissions.value.forEach(permission => newPermissions.push(permission.parent + ':' + permission.name))

    //   return newPermissions
    // })

    // const getUsersAbilities = computed(() =>
    //   userPermissions.value.flatMap(permission => permission.defaultPermission)
    // )

    const save = async () => {
      try {
        if (props.admin !== null) {
          await root.$apollo.mutate({
            mutation: gql`
              mutation ($user: UserInput!) {
                updateUser(user: $user) {
                  id
                  email
                  #role
                  abilities
                }
              }
            `,
            variables: {
              user: {
                id: props.admin.id,
                email: adminsEmail.value,
                // role: adminsRole.value,
                abilities: userPermissions.value,
              },
            },
          })
          emit('success')
          vm.$toastr.s('User successfully updated!')
        } else {
          await root.$apollo.mutate({
            mutation: gql`
              mutation ($user: UserInput!) {
                addUser(user: $user) {
                  id
                  email
                  #role
                  abilities
                }
              }
            `,
            variables: {
              user: {
                email: adminsEmail.value.toLowerCase(),
                // role: adminsRole.value,
                abilities: userPermissions.value,
              },
            },
          })
          vm.$toastr.s('User successfully added!')
          emit('success')
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          vm.$toastr.e('Unauthorized')
          // vm.logout()
        } else if (err.graphQLErrors != null && err.graphQLErrors.length > 0) {
          err.graphQLErrors.forEach(element => {
            vm.$toastr.e(element.message)
          })
        } else if (err.networkError) {
          vm.$toastr.e('Network Error')
        } else {
          vm.$toastr.e('Server Error')
        }
      } finally {
        emit('update:admin', null)
        emit('update:show', false)
        isDialogVisible.value = false
      }
    }

    const isValid = computed(() => {
      if (
        adminsEmail.value !== undefined
        //   && adminsRole.value !== undefined
        //   && adminsRole.value !== null
      ) {
        return true
      }

      return false
    })

    return {
      isDialogVisible,
      //   usersRole,
      adminsEmail,
      //   adminsRole,
      closeModal,
      save,
      // validators: {
      //   required,
      //   emailValidator,
      // },
      errorMessages,
      isValid,
      userPermissions,
      // getUsersAbilities,
      allSelected,

      title,
    }
  },

  validations: {
    adminsEmail: {
      required,
      email,
    },
  },

  methods: {
    getPermissionLabel(permission) {
      return permission[0].toUpperCase() + permission.slice(1)
    },
  },
}
</script>

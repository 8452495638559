<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              Are you sure you want to delete this user?
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          outlined
          @click="closeModal"
        >
          Close
        </v-btn>
        <v-btn
          color="error"
          @click="deleteUser"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getCurrentInstance, ref } from '@vue/composition-api'
import gql from 'graphql-tag'
import { initialAbility } from '@/plugins/acl/config'

export default {
  name: 'DeleteDialog',
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    admin: {
      type: Object,
      required: true,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const isDialogVisible = ref(props.show)
    const vm = getCurrentInstance().proxy

    const closeModal = () => {
      isDialogVisible.value = false
      emit('update:admin', null)
      emit('update:show', false)
    }

    const deleteUser = async () => {
      try {
        await root.$apollo.mutate({
          mutation: gql`
            mutation ($id: ID!) {
              deleteUser(id: $id) {
                id
                email
                role
              }
            }
          `,
          variables: {
            id: props.admin.id,
          },
        })
        emit('success')
        vm.$toastr.s('User successfully deleted!')
      } catch (err) {
        if (err?.response?.status === 401) {
          vm.$toastr.e('Unauthorized')
          // Remove from localStorage
          localStorage.removeItem('accessToken')
          localStorage.removeItem('userData')
          localStorage.removeItem('userAbility')
          localStorage.removeItem('userRole')

          // Reset ability
          vm.$ability.update(initialAbility)

          // Remove apollo cache
          vm.$apolloProvider.defaultClient.cache.data.clear()
          // Redirect to login page
          vm.router.push({ name: 'auth-login' })
        } else if (err.graphQLErrors != null && err.graphQLErrors.length > 0) {
          err.graphQLErrors.forEach(element => {
            vm.$toastr.e(element.message)
          })
        } else if (err.networkError) {
          vm.$toastr.e('Network Error')
        } else {
          vm.$toastr.e('Server Error: ', err)
        }
      } finally {
        emit('update:admin', null)
        emit('update:show', false)
        isDialogVisible.value = false
      }
    }

    return {
      isDialogVisible,
      closeModal,
      deleteUser,
    }
  },
}
</script>

<template>
  <div class="my-3">
    <v-row class="px-3">
      <v-btn
        v-if="$can('create', 'Administrators')"
        color="primary"
        dark
        class="mb-1"
        @click="showModal = true"
      >
        Add new
      </v-btn>
      <admin-dialog-form
        v-if="showModal"
        :show.sync="showModal"
        :role="role"
        title="Add administator"
      />
      <v-spacer></v-spacer>
      <div>
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          dense
          outlined
          clearable
          maxlength="150"
        ></v-text-field>
      </div>
    </v-row>
    <admins-table
      ref="adminsTable"
      :search="search"
      @change="refetchData"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { ref, provide } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import AdminsTable from '@/views/components/administrators/AdminsTable.vue'
import AdminDialogForm from '@/views/components/administrators/AdminDialogForm.vue'
// import AbilityMixin from '@/mixins/AbilityMixin'
import gql from 'graphql-tag'

export default {
  components: {
    AdminsTable,
    AdminDialogForm,
  },
  // mixins: [AbilityMixin],

  apollo: {
    appSections: {
      query: gql`
        query {
          appSections {
            id
            key
            name
            disabled
            allowedPermissions {
              name
              key
            }
          }
        }
      `,
      result(res) {
        if (res.error) return
        this.sections = res?.data?.appSections || []
      },
    },
  },

  setup() {
    const role = localStorage.getItem('userRole')
    const search = ref('')
    const showModal = ref(false)

    const sections = ref([])
    provide('appSections', sections)

    return {
      icons: {
        mdiMagnify,
      },
      role,
      search,
      showModal,

      sections
    }
  },

  methods: {
    refetchData() {
      this.$refs.adminsTable.getAdmins()
    },
  },

  watch: {
    showModal(newVal, oldVal) {
      if (newVal === false && oldVal === true) this.refetchData()
    },
  },
}
</script>

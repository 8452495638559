var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"position-relative"},[_c('tr',[_c('th',{staticClass:"text-uppercase",on:{"click":function($event){return _vm.changeOrder('EMAIL')}}},[_vm._v(" Email "),_c('sorting-arrows',{attrs:{"direction":_vm.column === 'EMAIL' ? _vm.order : null}})],1),(_vm.role == 'superadmin')?_c('th',{staticClass:"text-center text-uppercase",on:{"click":function($event){return _vm.changeOrder('ROLE')}}},[_vm._v(" Role "),_c('sorting-arrows',{attrs:{"direction":_vm.column === 'ROLE' ? _vm.order : null}})],1):_vm._e(),_c('th',{staticClass:"text-center text-uppercase",on:{"click":function($event){return _vm.changeOrder('LAST_SESSION')}}},[_vm._v(" Last Session "),_c('sorting-arrows',{attrs:{"direction":_vm.column === 'LAST_SESSION' ? _vm.order : null}})],1),_c('th',{staticClass:"text-center text-uppercase"},[_vm._v(" Actions ")])]),_c('v-progress-linear',{attrs:{"bottom":"","absolute":"","indeterminate":"","active":_vm.$apollo.queries.users.loading}})],1),_c('tbody',{staticClass:"position-relative"},[(_vm.admins.length == 0)?_c('tr',[_c('td',{staticClass:"text-center my-5",attrs:{"colspan":"4"}},[_c('h3',[_vm._v(" No items ")])])]):_vm._l((_vm.admins),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.email))]),(_vm.role == 'superadmin')?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.role[0].toUpperCase() + item.role.slice(1))+" ")]):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.last_session ? _vm.$moment(item.last_session).format('YYYY-MM-DD') : 'Never')+" ")]),_c('td',{staticClass:"text-center"},[(!_vm.$userService.isCurrentUser(item))?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$can('update', 'Administrators'))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$can('destroy', 'Administrators'))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1):_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleView(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])],1)])])})],2)]},proxy:true}])}),(_vm.admins)?_c('pagination',{attrs:{"count":_vm.countPages,"page":_vm.currentPage},on:{"update:page":function($event){_vm.currentPage=$event}}}):_vm._e(),(_vm.showModal && _vm.$can('update', 'Administrators'))?_c('admin-dialog-form',{attrs:{"show":_vm.showModal,"admin":_vm.selectedAdmin,"role":_vm.role},on:{"update:show":function($event){_vm.showModal=$event},"update:admin":function($event){_vm.selectedAdmin=$event},"success":function($event){return _vm.getAdmins()}}}):_vm._e(),(_vm.showDeleteModal)?_c('delete-dialog',{attrs:{"show":_vm.showDeleteModal,"admin":_vm.selectedAdmin,"title":"Delete User"},on:{"update:show":function($event){_vm.showDeleteModal=$event},"update:admin":function($event){_vm.selectedAdmin=$event},"success":function($event){return _vm.getAdmins()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }